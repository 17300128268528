import React from 'react';
import '../Styles/about.css';
import Arrow from '../utils/LongArrow';
import smallArrow from '../utils/SmallArrow';
import aboutImage from '../images/about_image.jpg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../utils/i18n';

export default function About() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleButtonNavigateClick = (e: any) => {
    e.preventDefault();
    navigate('/form');
    window.scrollTo(0, 0);
  };
  return (
    <div className='about-container'>
      <div className='about-left animate__animated animate__fadeInLeft'>
        <div className='about-support'>
          <div className='container-arrow'>
            <span id='about-title'>
              {t('aboutTitle')} <b>{t('aboutTitleSupport')}</b>
            </span>
            {smallArrow()}
          </div>
          <span id='about-paragraph'>
            <b>{t('aboutParagraphTitle')}</b> {t('aboutParagraphTitleSupport')}{' '}
          </span>
          <span id='about-content'>
            {t('aboutContentOne')} <br></br>
            <br></br>
            {t('in')}
            <span
              style={{
                fontFamily: 'Proxima Nova Rg Regular',
              }}
            >
              90
            </span>
            {t('aboutContentOneTwo')}
            <br />
            <br />
            {t('aboutContentTwo')}
            <br></br>
            <br></br>
            {t('aboutContentThree')}
          </span>
          <div id='about-footer'>
            <div className='inner-about-footer'>
              <Arrow />
              <div>
                <span>{t('aboutContentFour')}</span>
                <br></br>
                <br></br>
                <span style={{ width: '210%' }}>{t('aboutContentFive')}</span>
              </div>
            </div>
            <div className='inner-about-footer' id='last-footer'></div>
          </div>
        </div>
      </div>

      <div className='about-image-container animate__animated animate__fadeInRight'>
        <img id='about-image' src={aboutImage} alt='aboutImage' />
        <div className='content-about-right'>
          <span id='about-right-span'>{t('aboutText')}</span>
          <button
            onClick={handleButtonNavigateClick}
            type='button'
            id='button-about'
          >
            {t('knowMoreButton')}
          </button>
        </div>
      </div>
    </div>
  );
}
