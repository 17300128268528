import React from 'react';
import '../Styles/address.css';
import addressLogo from '../images/address-logo.svg';
import whatsImage from '../images/whats_image.svg';
import emailImage from '../images/email_image.svg';

export default function Address() {
  return (
    <div className='address-container'>
      <img src={addressLogo} alt='logo address' id='address-logo' />
      <div className='address-content'>
        <div className='address-up'>
          <span id='address-text'>
            Rua Visconde do Rio Branco, 1488{'    '}|{'    '}Sala 909{'    '}|
            {'    '}Curitiba - Paraná
          </span>
        </div>
        <div className='address-down'>
          <img src={whatsImage} alt='imagem whats' />{' '}
          <span> + 55 | 41 | 9 9253-3413 | </span>
          <img src={emailImage} alt='imagem email' />{' '}
          <span>contato@sbbanks.com.br</span> <br></br>
        </div>
        <span id='cnpj-span'>SB Bank Ltda - CNPJ 51.798.094/0001-27</span>
      </div>
    </div>
  );
}
