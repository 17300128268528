import React from 'react';
const smallArrow = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='15'
      viewBox='0 0 26 15'
      fill='none'
    >
      <path d='M13 15L25.1244 0H0.875645L13 15Z' fill='#D6AD28' />
    </svg>
  );
};

export default smallArrow;
