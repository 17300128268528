import React from 'react';
import '../Styles/performance.css';
import smallArrow from '../utils/SmallArrow';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import '../utils/i18n';

export default function Performance() {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className='performance-container'>
      <div className='performance-support'>
        <div
          ref={ref}
          className={`container-arrow animate__animated ${
            inView ? 'animate__fadeIn' : 'container-arrow'
          }`}
        >
          <h1 id='performance-title'>
            {t('performanceTitle')} <b>{t('performanceTitleSupport')}</b>
          </h1>
          {smallArrow()}
        </div>
        <span
          id='performance-content'
          className={`animate__animated ${inView ? 'animate__fadeIn' : ''}`}
        >
          {t('performanceContent')} <br></br> {t('performanceContentSupport')}
        </span>
        <div className='performance-models'>
          <fieldset
            className={`performance-model-card animate__animated ${
              inView ? 'animate__fadeInLeft' : 'performance-model-card'
            }`}
          >
            <legend>1º {t('performanceModel')}</legend>
            <span>
              <b>{t('performanceFirstModelTitle')}</b> <br></br>
              {t('performanceFirstModelContent')}
            </span>
          </fieldset>
          <fieldset
            className={`performance-model-card animate__animated ${
              inView ? 'animate__fadeInRight' : 'performance-model-card'
            }`}
          >
            <legend>2º {t('performanceModel')}</legend>
            <span>
              <b>{t('performanceSecondModelTitle')}</b> <br></br>
              {t('performanceSecontModelContent')}
            </span>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
