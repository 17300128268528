import React from 'react';
import '../Styles/questions.css';
import questionsImage from '../images/questions.svg';
import AccordionQuestions from '../utils/Accordion';
import smallArrow from '../utils/SmallArrow';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

export default function Questions() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { t } = useTranslation();
  return (
    <div ref={ref} className={`questions-container animate__animated ${
      inView ? 'animate__fadeIn' : 'questions-container'
    }`}>
      <div className='container-arrow'>
        <span id='questions-title'>
          {t('questionsTitle')} <b>{t('questionsTitleSupport')}</b>
        </span>
        {smallArrow()}
      </div>
      <div className='questions-content'>
        <img src={questionsImage} alt='questions' id='questions-img' />
        <div className='questions-accordion'>
          <AccordionQuestions />
        </div>
      </div>
    </div>
  );
}
