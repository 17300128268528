import React from 'react';
import '../Styles/main.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Address from '../Components/Address';
import { Form } from '../Components/Form';

export default function FormPage() {
  return (
    <main className='main-container'>
      <Header />
      <Form />
      <Address />
      <Footer />
    </main>
  );
}
