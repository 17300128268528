import React from 'react';
import Header from '../Components/Header';
import Principal from '../Components/Principal';
import About from '../Components/About';
import Performance from '../Components/Performance';
import Public from '../Components/Public';
import Benefits from '../Components/Benefits';
import Products from '../Components/Products';
import Service from '../Components/Service';
import Contact from '../Components/Contact';
import Questions from '../Components/Questions';
import Address from '../Components/Address';
import Footer from '../Components/Footer';
import '../Styles/main.css';
import FinTechBoost from '../Components/FinTechBoost';

export default function MainPage() {
  return (
    <main className='main-container'>
      <Header />
      <Principal />
      <FinTechBoost />
      <About />
      <Benefits />
      <Performance />
      <Public />
      <Products />
      <Service />
      <Contact />
      <Questions />
      <Address />
      <Footer />
    </main>
  );
}
