import React, { useEffect } from 'react';
import '../Styles/header.css';
import mainLogo from '../images/main_logo.svg';
import usaFlag from '../images/usa_flag.svg';
import brFlag from '../images/br_flag.svg';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';
import { handleLinkClick } from '../utils/Functions';
import SliderMenu from '../utils/Slidermenu';

export default function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const flags = document.querySelector('.header-flags');
    const slide = document.querySelector('.bm-item-list');
    const verifywidth = window.innerWidth < 640;

    if (verifywidth && flags && slide) {
      slide.appendChild(flags);
      if (flags instanceof HTMLElement) {
        const images = flags.querySelectorAll('img');
        flags.style.justifyContent = 'center'
        images.forEach((image) => {
          image.style.width = '50px'; 
        });
      }
    }
  }, []);

  return (
    <div className='main-header' id='outer-container'>
      <div
        className='header-support animate__animated animate__fadeInDown'
        id='page-wrap'
      >
        <img src={mainLogo} alt='main_logo_header' id='main-logo' />
        <header className='header'>
          <a
            href='https://google.com'
            target='blank'
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('about-title');
            }}
            className='link-header'
          >
            {t('headerAbout')}
          </a>
          <a
            href='google.com'
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('performance-title');
            }}
            className='link-header'
          >
            {t('headerAtuation')}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('public-title');
            }}
            href='google.com'
            className='link-header'
          >
            {t('headerPublic')}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('products-title');
            }}
            href='google.com'
            className='link-header'
          >
            {t('headerProducts')}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('service-title');
            }}
            href='google.com'
            className='link-header'
          >
            {t('headerService')}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick('contact-container');
            }}
            href='google.com'
            className='link-header'
          >
            {t('headerContact')}
          </a>
        </header>
        <div className='header-flags'>
          <img
            src={usaFlag}
            alt='bandeira dos eua'
            onClick={() => changeLanguage('en')}
            className='header-flag'
          />
          <img
            src={brFlag}
            alt='bandeira do br'
            onClick={() => changeLanguage('pt')}
            className='header-flag'
          />
        </div>
      </div>
      <SliderMenu
        handleLinkClick={handleLinkClick}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      />
    </div>
  );
}
