import React, { useEffect } from 'react';
import '../Styles/products.css';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';
// icons-imports

// upper
import contaDigital from '../images/icons/contaDigital.svg';
import transfer from '../images/icons/transfer.svg';
import pix from '../images/icons/pix.svg';
import credito from '../images/icons/credito.svg';
import investimento from '../images/icons/investimentos.svg';

// bottom

import cartaoPrePago from '../images/icons/cartaoPrePago.svg';
import boleto from '../images/icons/boleto.svg';
import saque from '../images/icons/banco.svg';
import maquininha from '../images/icons/maquininhas.svg';
import pagamento from '../images/icons/pagamentoContas.svg';
import servicosDigitais from '../images/icons/servicosDigitais.svg';
import smallArrow from '../utils/SmallArrow';

export default function Products() {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    const largura = window.innerWidth;
    const icon = document.getElementById('servicos-digitais');
    const up = document.getElementById('products-icons-top');
    const bottom = document.getElementById('procuts-icons-bottom');
    if (up && icon && largura <= 430) {
      up?.appendChild(icon);
    }
    if (icon && largura > 430) {
      bottom?.appendChild(icon);
    }
  }, []);
  return (
    <div className='products-container ref={ref}'>
      <div
        ref={ref}
        className={`container-arrow animate__animated ${
          inView ? 'animate__fadeInUp' : 'container-arrow'
        }`}
      >
        <span id='products-title'>{t('headerProducts')}</span>
        {smallArrow()}
      </div>
      <div className='products-icons-container'>
        <div id='products-icons-top'>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img
              src={contaDigital}
              alt='conta_digital'
              className='products-icons'
            />
            <span id='icon-text'>{t('digitalAccount')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img
              src={transfer}
              alt='transferencia'
              className='products-icons'
            />
            <span id='icon-text'>{t('transfer')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={pix} alt='pix' className='products-icons' />
            <span id='icon-text'>Pix</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={credito} alt='credito' className='products-icons' />
            <span id='icon-text'>{t('credit')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img
              src={investimento}
              alt='investimento'
              className='products-icons'
            />
            <span id='icon-text'>{t('invest')}</span>
          </div>
        </div>
        <div id='products-icons-bottom'>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img
              src={cartaoPrePago}
              alt='cartao Pre Pago'
              className='products-icons'
            />
            <span id='icon-text'>{t('prePaidCard')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={boleto} alt='boleto' className='products-icons' />
            <span id='icon-text'>{t('boleto')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={saque} alt='saque' className='products-icons' />
            <span id='icon-text'>{t('banco')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={maquininha} alt='maquininha' className='products-icons' />
            <span id='icon-text'>{t('maquininha')}</span>
          </div>
          <div
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img src={pagamento} alt='pagamento' className='products-icons' />
            <span id='icon-text'>{t('accountDebit')}</span>
          </div>
          <div
            id='servicos-digitais'
            className={`icon-container animate__animated ${
              inView ? 'animate__fadeInUp' : 'icon-container'
            }`}
          >
            <img
              src={servicosDigitais}
              alt='servicos Digitais'
              className='products-icons'
            />
            <span id='icon-text'>{t('digitalService')}</span>
          </div>
        </div>
      </div>
      <div className='products-footer'>
        <span id='footer-content'>{t('productsText')}</span>
      </div>
    </div>
  );
}
