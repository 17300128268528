import React from 'react';
import '../Styles/service.css';
import serviceImage from '../images/service.jpg';
import smallArrow from '../utils/SmallArrow';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

export default function Service() {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className='service-container'>
      <img src={serviceImage} alt='service_image' id='service-image' />
      <div
        ref={ref}
        className={`service-support animate__animated ${
          inView ? 'animate__fadeInLeft' : 'service-support'
        }`}
      >
        <div className='service-content'>
          <div className='container-arrow'>
            <span id='service-title'>
              {t('serviceTitle')} <b>{t('serviceTitleSupport')}</b>
            </span>
            {smallArrow()}
          </div>
          <div className='service-subtitle'>
            <span id='service-subtitle-principal'>
              {t('serviceTitlePrincipal')}
            </span>
            <span id='service-subtitle-content'>
              {t('servicePrincipalContent')}
            </span>
          </div>
          <div className='service-resume'>
            <div className='service-card'>
              <div className='container-arrow'>
                <span className='service-card-title'>
                  {t('serviceLeftTitle')}
                </span>
                {smallArrow()}
              </div>
              <span className='service-card-content'>
                {t('serviceLeftContent')}
              </span>
            </div>
            <div className='service-card-space'></div>
            <div className='service-card'>
              <div className='container-arrow'>
                <span className='service-card-title'>
                  {t('serviceRightTitle')}
                </span>
                {smallArrow()}
              </div>
              <span className='service-card-content'>
                {t('serviceRightContent')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
