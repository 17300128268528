import { useTranslation } from 'react-i18next';
import finTechBoostImage from '../images/FinTechBoost.jpg';
import icon from '../images/banner2_icon.png';
import '../Styles/finTechBoost.css';
import '../utils/i18n';

export default function FinTechBoost() {
  const { t } = useTranslation();
  return (
    <div className='finTechBoostContainer'>
      <img id='finTechImage' src={finTechBoostImage} alt='finTechBoostImage' />
      <div className='finTechBoostRightContainer'>
        <div className='finTechBoostTopSupport'>
          <div>
            <span id='titleFinTechBoost'>
              <b>{t('finTechBoostText1')}</b>
            </span>
            <br />
            <br />
            <span
              style={{
                fontFamily: 'Eastman Roman Trial Bold',
              }}
            >
              <b>{t('finTechBoostText2')}</b>
            </span>
            <br />
            <span>{t('finTechBoostText3')}</span>
          </div>
        </div>
        <div className='finTechBoostBottomSupport'>
          <div>
            <span>
              <b>{t('finTechBoostText4')}</b>
            </span>
            <br />
            <div style={{ display: 'flex' }}>
              <div id='support-div-fintechboost'>
                <span>
                  <b>
                    {t('finTechBoostText5')}{' '}
                    <span style={{ fontFamily: 'Arial' }}>
                      {t('oneHundred')}
                    </span>{' '}
                    {t('finTechBoostText5Sup')}
                  </b>
                </span>
                <br />
                <span id='finTechBoostNomalText'>{t('finTechBoostText6')}</span>
                <br />
                <br />
                <span>
                  <b>{t('finTechBoostText7')}</b>
                </span>
              </div>
              <img src={icon} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
