import React from "react";
import zeroBanner from "../images/banner0.png";
import firstBanner from "../images/banner.jpg";
import secondBanner from "../images/banner2.jpg";
import thirdBanner from "../images/banner3.png";
import icon from "../images/banner2_icon.png";
import lineEllipse from "../images/line_ellipse.png";
import "../utils/i18n";
import "./banners.css";
import { useTranslation } from "react-i18next";

interface BannerProps {
  id: string;
}

export const ZeroBanner: React.FC<BannerProps> = (BannerProps) => {
  const { t } = useTranslation();
  return (
    <div className="firstBannerSupport">
      <div className="firstBanner">
        <span>
          <b>{t("firstBannerText")}</b> <br></br>
        </span>
        <span>
          <b>{t("firstBannerTextSupport")}</b> {t("firstBannerTextFinal")}
        </span>
      </div>
      <img
        id={BannerProps.id}
        className="main-banner"
        src={zeroBanner}
        alt="first banner"
      />
    </div>
  );
};

export const FirstBanner: React.FC<BannerProps> = (BannerProps) => {
  const { t } = useTranslation();
  return (
    <div className="slide-support">
      <div className="span-container">
        <span>
          {t("firstBannerText1")} <b>{t("firstBannerText2")}</b> <br />{" "}
          {t("and")} <b>{t("firstBannerText3")}</b> {t("firstBannerText4")}{" "}
          <b> {t("firstBannerText5")}</b>
        </span>
      </div>
      <img
        id={BannerProps.id}
        className="main-banner"
        src={firstBanner}
        alt="first banner"
      />
    </div>
  );
};

export const SecondBanner: React.FC<BannerProps> = (BannerProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="second-banner-text-container">
        <img id="second-banner-icon" src={icon} alt="second banner icon" />

        <span id="second-banner-up-text">
          {" "}
          {t("secondBannerText1")}
          <br />
          <b>
            <span id="secondBannerTextTitle">{t("secondBannerText2")} </span>{" "}
          </b>
          <br />
          {t("and")}{" "}
          <span id="secondBannerTextTitle">
            <b>{t("secondBannerText3")}</b>
          </span>{" "}
          <br />
          {t("secondBannerText4")}
        </span>
        <img id="line-elipse" src={lineEllipse} alt="line Ellipse" />
      </div>
      <img id={BannerProps.id} src={secondBanner} alt="first banner" />
    </div>
  );
};

export const ThirdBanner: React.FC<BannerProps> = (BannerProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="third-banner-texts">
        <div className="third-upper-banner-text">
          <span>
            <b>{t("thirdBannerText")}</b> <br />
          </span>
          <span>{t("thirdBannerTextTwo")}</span>
        </div>
        <div className="third-down-banner-text">
          <span>
            {t("thirdBannerTextThree")} <br />
          </span>
          <span>
            <b>{t("thirdBannerTextFour")}</b>
          </span>
        </div>
      </div>
      <img id={BannerProps.id} src={thirdBanner} alt="first banner" />
    </div>
  );
};
