import React from 'react';

const LongArrow: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='66'
      viewBox='0 0 31 66'
      fill='none'
    >
      <path
        d='M31 33L2.83709e-06 66L4.27957e-06 33L5.72205e-06 -1.35505e-06L31 33Z'
        fill='#D6AD28'
      />
    </svg>
  );
};

export default LongArrow;
