import React from "react";
import "../Styles/benefits.css";
import benefitsImage from "../images/benefits.jpg";
import { Trans, useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import "../utils/i18n";

export default function Benefits() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const handleButtonNavigateClick = (e: any) => {
    e.preventDefault();
    navigate("/form");
    window.scrollTo(0, 0);
  };
  return (
    <div className="benefits-container">
      <div
        ref={ref}
        className={`benefits-left animate__animated ${
          inView ? "animate__fadeInLeft" : "benefits-left"
        }`}
      >
        <div className="benefits-title-container">
          <span id="benefits-title">
            <b>{t("benefitsTitleOne")}</b> {t("benefitsTitleTwo")} <br></br>
            {t("benefitsTitleThree")} <b>{t("benefitsTitleFour")}</b>
          </span>
          <div className="benefits-fill"></div>
        </div>
        <div className="benefits-cards-container">
          <span id="benefits-cards-span">
            <Trans i18nKey="benefitsNewText" />
          </span>
          <br />
          <span id="benefits-cards-span">
            <Trans i18nKey="benefitsNewText2" />
          </span>
          <div className="benefits-cards-org">
            <div className="benefits-card">
              <span className="benefits-card-title">
                {t("benefitsFirstCardTitle")}
                <br></br>
                {t("benefitsFirstCardSupport")}
              </span>
              <br></br>

              <span className="benefits-card-content">
                {t("benefitsFirstCardContent")}
              </span>
            </div>
            <div className="benefits-card">
              <span className="benefits-card-title">
                {t("benefitsSecondCardTitle")}
                <br></br>
                {t("benefitsSecondCardSupport")}
              </span>
              <br></br>
              <span className="benefits-card-content">
                {t("benefitsSecondCardContent")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        id="benefits-image"
        className={` animate__animated ${inView ? "animate__fadeInRight" : ""}`}
      >
        <img id="about-image" src={benefitsImage} alt="aboutImage" />
        <div className="content-about-right">
          <span id="about-right-span">
            <b>{t("benefitsText")}</b>
            <br />
            {t("benefitsTextSupport")}
          </span>
          <button
            type="button"
            onClick={handleButtonNavigateClick}
            id="button-about"
          >
            {t("knowMoreButton")}
          </button>
        </div>
      </div>
    </div>
  );
}
