import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import './slidemenu.css';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

const SliderMenu = (props) => {
  const { handleLinkClick } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };
  const openMenu = () => {
    setIsOpen(true);
  };
  return (
    <Menu right isOpen={isOpen} onClose={() => setIsOpen(false)} onOpen={() => openMenu()}>
      <a
        href='https://google.com'
        target='blank'
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('about-title');
        }}
        className='slider-text'
      >
        {t('headerAbout')}
      </a>
      <a
        href='google.com'
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('performance-title');
        }}
        className='slider-text'
      >
        {t('headerAtuation')}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('public-title');
        }}
        href='google.com'
        className='slider-text'
      >
        {t('headerPublic')}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('products-title');
        }}
        href='google.com'
        className='slider-text'
      >
        {t('headerProducts')}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('service-title');
        }}
        href='google.com'
        className='slider-text'
      >
        {t('headerService')}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          closeMenu();
          handleLinkClick('contact-container');
        }}
        href='google.com'
        className='slider-text'
      >
        {t('headerContact')}
      </a>
    </Menu>
  );
};

export default SliderMenu;
