import React from 'react';
import '../Styles/contact.css';
import contactLogo from '../images/contact-logo.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

export default function Contact() {
  
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButtonNavigateClick = (e: any) => {
    e.preventDefault();
    navigate('/form');
    window.scrollTo(0, 0);
  };
  return (
    <div className='contact-container' id='contact-container'>
      <div className='contact-support'>
        <div className='contact-text'>
          <img id='contact-image' src={contactLogo} alt='logo contact' />
          <div className='contact-text-fill'></div>
          <span id='contact-text'>{t('contactText')}</span>
        </div>
        <div className='contact-button'>
          <button
            id='contact-button'
            onClick={handleButtonNavigateClick}
            type='button'
          >
            {t('contactButtonText')}
          </button>
        </div>
      </div>
    </div>
  );
}
