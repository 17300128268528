import React, { useState } from 'react';
import formImage from '../images/form-image.jpg';
import '../Styles/form.css';
import '../utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { emailSendler } from '../utils/Functions';
import { Button, Modal } from 'react-bootstrap';

export const Form: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [business, setBusiness] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');
  const [show, setShow] = useState(false);
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  

  const formatPhone = (phone: string) => {
    if (phone.length <= 2) {
      return `(${phone}`;
    } else if (phone.length <= 3) {
      return `(${phone}) `;
    } else if (phone.length <= 7) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(3)}`;
    } else {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(
        3,
        7
      )}-${phone.slice(7, 11)}`;
    }
  };

  const handleInputName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const handleInputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleInputBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBusiness(value);
  };

  const handleInputPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    const formattedInput = formatPhone(input);
    setPhone(formattedInput);
  };

  const handleButtonHomeClick = (e: any) => {
    e.preventDefault();
    navigate('/');
    window.scrollTo(0, 0);
  };
  const handleClose = () => setShow(false);

  async function buttonClicker() {
    const data = { name, email, business, phone };
    if (!data || !email || !business || !phone) {
      setMessage('Preencha todos os campos!');
      setDisplayMessage(true);
      return;
    }

    if (!emailRegex.test(email)) {
      setMessage('E-mail inválido');
      setDisplayMessage(true);
      return;
    }
    setShow(true);
    setTitle('Enviando e-mail...');
      setModalMessage(
        'Aguarde enquanto tentamos enviar o seu e-mail.'
      );
    try {
      await emailSendler(data);
      setShow(true);
      setTitle('Mensagem enviada com sucesso');
      setModalMessage(
        'Recebemos sua mensagem, em breve nosso time entrará em contato.'
      );
    } catch (error: any) {
      setShow(true);
      setTitle('Ocorreu um erro');
      setModalMessage(
        'Infelizmente ocorreu um erro ao tentar enviar sua mensagem, favor tentar novamente mais tarde.'
      );
      console.log(error.message);
    }
  }
  return (
    <div className='form-container'>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='form-left-div'>
        <img id='form-image' src={formImage} alt='formImage' />
      </div>

      <div className='work-form-content '>
        <div className='form-title animate__animated animate__fadeInDown'>
          <span>
            <b>Solicite seu Atendimento</b>
          </span>
          <span>Entre em Contato Conosco!</span>
        </div>
        {displayMessage && <span id='sender-email-success'>{message}</span>}

        <form action='submit'>
          <label
            className='form-label animate__animated animate__fadeInDown'
            htmlFor='name'
          >
            <span>{t('name')}</span>
            <input
              type='text'
              name='name'
              className={`work-form-input`}
              onChange={handleInputName}
              id='work-form-name'
            />
          </label>

          <label
            className='form-label animate__animated animate__fadeInDown'
            htmlFor='business'
          >
            <span>{t('business')}</span>
            <input
              type='text'
              name='business'
              id='work-form-business'
              onChange={handleInputBusiness}
              className={`work-form-input`}
            />
          </label>

          <label
            className='form-label animate__animated animate__fadeInDown'
            htmlFor='phone'
          >
            <span>{t('phone')} </span>
            <input
              type='text'
              value={phone}
              onChange={handleInputPhone}
              name='phone'
              id='work-form-phone'
              className={`work-form-input`}
            />
          </label>
          <label
            className='form-label animate__animated animate__fadeInDown'
            htmlFor='email'
          >
            <span>E-mail</span>
            <input
              type='text'
              name='email'
              id='work-form-email'
              onChange={handleInputEmail}
              className={`work-form-input`}
            />
          </label>
          <div className='buttons-container'>
            <button
              type='button'
              className='animate__animated animate__fadeInDown'
              onClick={buttonClicker}
              id='work-form-button'
            >
              {t('submit')}
            </button>
            <button
              onClick={handleButtonHomeClick}
              type='button'
              className='animate__animated animate__fadeInDown'
              id='work-form-button'
            >
              {t('back')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
