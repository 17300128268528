import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './Styles/main.css';
import MainPage from './Pages/MainPage';
import FormPage from './Pages/FormPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/form' element={<FormPage />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
