import React from 'react';
import '../Styles/public.css';
import publicImage from '../images/public.jpg';
import smallArrow from '../utils/SmallArrow';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import '../utils/i18n';

export default function Public() {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  return (
    <div className='public-container'>
      <img id='public-image' src={publicImage} alt='image_public' />
      <div
        ref={ref}
        className={`public-content animate__animated ${
          inView ? 'animate__fadeInRight' : 'public-content'
        }`}
      >
        <div className='container-arrow'>
          <span id='public-title'>
            {t('publicTitle')} <b>{t('publicTitleSupport')}</b>
          </span>
          {smallArrow()}
        </div>
        <div className='public-cards-container'>
          <div id='public-cards-text-div'>
            <span id='public-cards-text-title'>{t('publicTextTitle')}</span>
            <span id='public-cards-text-content'>{t('publicTextContent')}</span>
          </div>
          <div id='public-cards-cards'>
            <div className='public-card'>
              <span className='public-card-title'>
                {t('firstCardTitle')} <br></br> (ERP’s)<br></br>
              </span>
              <span className='public-card-content'>
                {t('firstCardContent')}
              </span>
              <br></br>
              <br></br>
              <span className='public-card-title'>
                {t('firstCardSubTitle')}
              </span>
              <span className='public-card-content'>
                <br></br>
                {t('firstCardSubContent')}
              </span>
            </div>
            <div className='public-card'>
              <span className='public-card-title'>
                {t('secondCardTitle')}
                <br></br>
              </span>
              <span className='public-card-content'>
                {t('secondCardContent')}
              </span>
              <br></br>
              <br></br>
              <span className='public-card-title'>
                {t('secondCardSubTitle')} <br></br>
              </span>
              <span className='public-card-content'>
                {t('secondCardSubContent')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
