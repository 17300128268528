import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    pt: {
      translation: {
        headerAbout: "SOBRE NÓS",
        headerAtuation: "FORMAS DE ATUAÇÃO",
        headerPublic: "PÚBLICO ALVO",
        headerProducts: "PRODUTOS",
        headerService: "NOSSO SERVIÇO",
        headerContact: "CONTATO",
        aboutTitle: "Sobre",
        aboutTitleSupport: "nós",
        aboutParagraphTitle: "O SB BANK",
        aboutParagraphTitleSupport: "CONTA COM UM TIME DE ESPECIALISTAS",
        aboutContentOne:
          "que tem como objetivo estruturar bancos digitais personalizados com sua marca e para seu nicho de mercado (Baas).",
        in: "Em ",
        aboutContentOneTwo:
          " dias o seu banco está rodando e toda responsabilidade com desenvolvimento, infraestrutura, homologações com Bandeiras e Adquirentes e certificações ficam por conta do SB Bank.",
        aboutContentTwo:
          "Além de prestar atendimento e suporte para todas as demandas necessárias, é responsável por realizar a viabilidade do negócio, estudando o mercado, desenvolvendo o Business Plan, assim como o planejamento estratégico e operacional.",
        aboutContentThree:
          "Cuidamos de tudo para facilitar para você, estruturando seu negócio do zero, partindo da regulamentação, segurança, tecnologia, até estrutura do time necessário e suporte.",
        aboutContentFour:
          "Apresentaremos o estudo de viabilidade econômica financeira antes de você contratar.Consultoria administrativa, financeira, mercadológica, jurídica e contábil.",
        aboutContentFive:
          "Forneceremos a expertise necessária para que o seu banco digital saia do papel!",
        performanceTitle: "Formas de",
        performanceTitleSupport: "Atuação",
        performanceContent: "o SB Bank oferece seu serviço em dois formatos",
        performanceContentSupport:
          "diferentes, visando atender as necessidades e objetivos de seus clientes:",
        performanceModel: "MODELO",
        performanceFirstModelTitle: "OPERAÇÃO INTERNA",
        performanceFirstModelContent:
          "Além de ser responsável pela estruturação e contratação do time necessário, o SB Bank é responsável pela operação do time como um todo, onde realizará toda a gestão e desenvolvimento diário do negócio.",
        performanceSecondModelTitle: "OPERAÇÃO EXTERNA",
        performanceSecontModelContent:
          "Após implantação, o cliente é responsável pelo time contratado, possuindo suporte e atendimento do SB Bank.",
        publicTitle: "PÚBLICO",
        publicTitleSupport: "ALVO",
        firstCardTitle: "Sistema de gestão",
        firstCardContent:
          "Sistemas que integram ferramentas de gestão para pequenas e médias empresas de diversos ramos de atividade como: varejo, atacado, serviços, auto peças, comércio, materiais de construção, alimentício, entre outros.",
        firstCardSubTitle: "Franqueadoras",
        firstCardSubContent:
          "O sistema de franquias requer muito controle da sua rede, com o banco digital você poderá dar assistência financeira para seus franqueados e assim aumentar a margem de lucro por meio de descontos em taxas e tarifas, além de poder financiar novas operações.",
        secondCardTitle: "Associações e Cooperativas de consumo",
        secondCardContent:
          "Constituído por cooperativas de abastecimento cujas atividades consistem em forma de estoques de bens de consumo e operam em negócios varejistas tais como farmácias, supermercados, postos de combustíveis e demais atividades como fornecedores de suprimentos e insumos (alimentos, roupas, medicamentos e outros artigos) para distribuição ao seu quadro social, em condições mais vantajosas de preço.",
        secondCardSubTitle:
          "Influenciadores | Mentores Consultores de Empresas",
        secondCardSubContent:
          "Pessoas ou empresas que oferecem treinamentos, cursos e mentorias empresariais.",
        benefitsTitleOne: "VANTAGENS",
        benefitsTitleTwo: "EM SER",
        benefitsTitleThree: "NOSSO",
        benefitsTitleFour: "CLIENTE",
        benefitsFirstCardTitle: `Vantagens para`,
        benefitsFirstCardSupport: "sua Rede",
        benefitsFirstCardContent:
          "Produtos e serviços bancários personalizados de acordo com as necessidades do mercado, visando economia e um melhor atendimento de seus clientes. Quanto maior a adesão da sua rede e utilização dos serviços do seu banco, maior os descontos de tarifas e taxas para vendas, financiamentos e empréstimos. Além de contarmos com um Marketplace onde é possível o cadastro de fornecedores e a disponibilização de cashback.",
        benefitsSecondCardTitle: "Vantagens para",
        benefitsSecondCardSupport: "sua Operação",
        benefitsSecondCardContent:
          "Transformar a sua rede em uma fonte de oportunidades financeiras, com possibilidade de monetização de sistema bancário. Além de monetizar ainda mais sua empresa com a geração de novas fontes de receitas, centralizar a operação financeira no se próprio banco vai lhe trazer economia em todas as tarifas e cobranças bancárias. Segundo estudos já realizados, somente com essa operação ja viabiliza seu banco digital.",
        productsTextOne:
          "Conta Digital: saldo, extrato, TED, PIX, pagamento de contas, emissão de boletos, saque nos terminais do Banco 24h e SaquePag.",
        productsText:
          "*Conta Digital: saldo, extrato, TED, PIX, pagamento de contas, emissão de boletos, saque nos terminais do Banco 24h e SaquePag. \n *Cartão Pré-Pago: o cartão com a marca da sua empresa, para compras online e física.\n CaaS - Card as a Service - Emita cartões para seus clientes, parceiros, fornecedores ou colaboradores, e torne sua marca ainda mais presente. \n *Maquininhas: aceitamos as principais bandeiras do mercado. Venda por cartão de débito ou crédito.",
        serviceTitle: "NOSSO",
        serviceTitleSupport: "SERVIÇO",
        serviceTitlePrincipal: "O NOSSO SUCESSO DEPENDE DO SEU",
        servicePrincipalContent:
          "Antes de fecharmos o contrato faremos um estudo de viabilidade da sua operação e de sua rede para calcular investimentos necessários e retornos esperados.",
        serviceLeftTitle:
          "Estudo mercadológico análise de viabilidade e Business Plan",
        serviceLeftContent:
          "Pagamento à vista descontado da mensalidade em caso de fechamento.",
        serviceRightTitle:
          "Setup Banco Digital\n + Setup Arquirencia\n(maquininhas)",
        serviceRightContent:
          "Valores variam de acordo com o estudo de viabilidade e de seu ramo de atuação.",
        contactText: "Entre em contato conosco",
        contactButtonText: "Saiba mais",
        accordionOneTitle: "O que é Banking as a Service?",
        accordionOneText:
          "Banking as a Service, que em tradução literal quer dizer Banco como serviço, autoriza que empresas de diferentes segmentos, como lojas de roupas e supermercados, ofereçam serviços financeiros aos clientes sem que eles precisem ir a Bancos ou instituições financeiras.",
        accordionTwoTitle:
          "A plataforma atende os regulatórios do Banco Central?",
        accordionTwoText:
          "Sim estamos em conformidade com a circular BACEN 3.765/15",
        accordionThreeTitle:
          "Quanto tempo demora para colocar meu Banco no ar?",
        accordionThreeText:
          "Após aprovação do seu banco junto ao Banco Central, em até 90 dias.",
        accordionFourTitle: "Qual o tipo de empresa preciso para operar?",
        accordionFourText:
          "Sociedade Ltda, ltda, Sa - Caso se estruture como uma IP. Já possuímos uma IP, você irá apenas ser o intermediário.",
        questionsTitle: "PERGUNTAS",
        questionsTitleSupport: "FREQUENTES",
        footerRights: "Todos os direitos reservados",
        footerTerms: "Termos de uso",
        footerPrivacy: "Política de Privacidade",
        digitalAccount: "Conta Digital",
        transfer: "Transferências",
        credit: "Crédito",
        invest: "Investimentos",
        prePaidCard: "Cartão pré-pago",
        boleto: "Emissão\nde Boletos",
        banco: "Banco 24h\ne Saque pag.",
        maquininha: "Maquininhas",
        accountDebit: "Pagamento de Contas",
        digitalService:
          "Serviços Digitais\n (recarga de celular,\ncréditos, uber,\nentre outros",
        firstBannerText: "CONSTRUINDO O FUTURO JUNTOS",
        firstBannerTextSupport: "E PREPARANDO",
        firstBannerTextFinal: "UMA NOVA EXPERIÊNCIA BANCÁRIA PARA VOCÊ.",

        firstBannerText1: "Crie sua",
        firstBannerText2: "fintech",
        and: "e",
        firstBannerText3: "rentabilize",
        firstBannerText4: "com \ncada transação feita \npelo",
        firstBannerText5: "seu ecossistema.",
        secondBannerText1: "Plataforma de",
        secondBannerText2: "Digital Bank",
        secondBannerText3: "Sub-Adquirência ",
        secondBannerText4: "completa no modelo Private Label.",
        thirdBannerText: "Inovação, \n Segurança \n e Compromisso.",
        thirdBannerTextTwo: "São alguns dos pilares do SB Bank.",
        thirdBannerTextThree: "Sua confiança, ",
        thirdBannerTextFour: "nossa prioridade.",
        benefitsText: "Solicite seu Atendimento",
        benefitsTextSupport: "Entre em contato conosco!",
        knowMoreButton: "Saiba mais",
        aboutText: "Entre em contato com o \n melhor time de Especialistas",
        name: "Nome",
        phone: "Telefone",
        submit: "Enviar",
        business: "Empresa",
        back: "Voltar",
        publicTextTitle:
          "Sua empresa tem uma rede de vendedores,\nfranquias, parceiros ou clientes?",
        publicTextContent:
          "Com o SB Bank você pode ofertar uma solução completa de meios de pagamento (máquinas de cartão, conta digital, cartão pré-pago...) com a sua marca. \nUma nova fonte de receita financeira para a sua empresa.",
        benefitsNewText:
          "<strong>Eliminamos as barreiras tecnológicas, financeiras e burocráticas</strong> para quem tem a necessidade de mover dinheiro facilmente. <strong>Oferecemos soluções próprias</strong> de pagamento, cobranças, gerenciador de recebíveis e você ainda ganha comissões em <strong>transações online ou em terminais de pagamento</strong>",
        benefitsNewText2:
          "<strong>Um modelo de negócios simples e transparente:</strong> sua empresa fica com uma porcentagem do que for transacionado pelos POS e também sobre as tarifas cobradas na conta digital dos usuários do seu Banco Digital (exemplo TED, PIX, emissão e boleto...)",
        finTechBoostText1:
          "Criamos, em poucos dias, o banco digital da sua empresa.",
        finTechBoostText2:
          "Sua empresa pode operar como uma instituição financeira",
        finTechBoostText3:
          "transformando o seu ecossistema em uma fonte inovadora de receitas financeiras.",
        finTechBoostText4:
          "Seu próprio banco digital e a sua marca na mão dos seus clientes. ",
        finTechBoostText5: "Plataforma",
        oneHundred: "100%",
        finTechBoostText5Sup: "white label ",
        finTechBoostText6:
          "com soluções completas aumentando a fidelização de seus clientes.",
        finTechBoostText7: "App e Internet Banking.",
      },
    },
    en: {
      translation: {
        headerAbout: "ABOUT US",
        headerAtuation: "FORMS OF ACTION",
        headerPublic: "TARGET AUDIENCE",
        headerProducts: "PRODUCTS",
        headerService: "OUR SERVICE",
        headerContact: "CONTACT",
        aboutTitle: "About",
        aboutTitleSupport: "us",
        aboutParagraphTitle: "THE SB BANK",
        aboutParagraphTitleSupport: "HAS A TEAM OF SPECIALISTS",
        aboutContentOne:
          "which aims to structure personalized digital banks with your brand and for your market niche (Baas).",
        aboutContentTwo:
          "In addition to providing service and support for all necessary demands, it is responsible for carrying out business viability, studying the market and developing the Business Plan, as well as strategic and operational planning.",
        aboutContentThree:
          "We take care of everything to make it easier for you, structuring your business from scratch, starting from regulation, security, technology, to the necessary team structure and support.",
        aboutContentFour:
          "We will present the economic and financial feasibility study before you hire. Administrative, financial, marketing, legal and accounting consultancy.",
        performanceTitle: "Forms of",
        performanceTitleSupport: "action",
        performanceContent: "SB Bank offers its service in two formats",
        performanceContentSupport:
          "different, aiming to meet the needs and objectives of its customers:",
        performanceModel: "MODEL",
        performanceFirstModelTitle: "INTERNAL OPERATION",
        performanceFirstModelContent:
          "In addition to being responsible for structuring and hiring the necessary team, SB Bank is responsible for operating the team as a whole, where it will carry out all daily management and development of the business..",
        performanceSecondModelTitle: "EXTERNAL OPERATION",
        performanceSecontModelContent:
          "After implementation, the client is responsible for the contracted team, receiving support and service from SB Bank.",
        publicTitle: "AUDIENCE",
        publicTitleSupport: "TARGET",
        firstCardTitle: "Management system",
        firstCardContent:
          "Systems that integrate management tools for small and medium-sized companies in different areas of activity, such as retail, wholesale, services, auto parts, commerce, construction materials, food, among others.",
        firstCardSubTitle: "Franchisors",
        firstCardSubContent:
          "The franchise system requires a lot of control over your network, with digital banking you can provide financial assistance to your franchisees and thus increase your profit margin through discounts on fees and rates, in addition to being able to finance new operations.",
        secondCardTitle: "Consumer associations and cooperatives",
        secondCardContent:
          "Consisting of supply cooperatives whose activities consist of stocks of consumer goods and operate in retail businesses such as pharmacies, supermarkets, gas stations and other activities as suppliers of supplies and inputs (food, clothing, medicines and other items) for distribution to its membership, under more advantageous price conditions.",
        secondCardSubTitle: "Influencers | Business Consultant Mentors",
        secondCardSubContent:
          "People or companies that offer training, courses and business mentoring.",
        benefitsTitleOne: "ADVANTAGES",
        benefitsTitleTwo: "OF BEING",
        benefitsTitleThree: "OUR",
        benefitsTitleFour: "CUSTOMER",
        benefitsFirstCardTitle: "Advantages for",
        benefitsFirstCardSupport: "your network",
        benefitsFirstCardContent:
          "Personalized banking products and services according to market needs, aiming for savings and better customer service. The greater the membership of your network and use of your bank's services, the greater the discounts on fees and rates for sales, financing and loans. In addition to having a Marketplace where it is possible to register suppliers and make cashback available.",
        benefitsSecondCardTitle: "Advantages for",
        benefitsSecondCardSupport: "your Operation",
        benefitsSecondCardContent:
          "Transform your network into a source of financial opportunities, with the possibility of monetizing the banking system. In addition to further monetizing your company by generating new sources of revenue, centralizing financial operations in your own bank will save you money on all banking fees and charges. According to studies already carried out, only this operation makes your digital bank viable.",
        productsText:
          "*Digital Account: Balance, statement, TED, PIX, bill payment, invoice issuance, withdrawal at Banco 24h and SaquePag terminals. \n *Prepaid Card: The card with your company's brand, for online and physical purchases.\n CaaS - Card as a Service - Issue cards to your customers, partners, suppliers or employees, and make your brand even more gift. \n *Maquininhas: We accept the main brands on the market. Sell ​​by debit or credit card.",
        serviceTitle: "OUR",
        serviceTitleSupport: "SERVICE",
        serviceTitlePrincipal: "OUR SUCCESS DEPENDS ON YOURS",
        servicePrincipalContent:
          "Before closing the contract, we will carry out a feasibility study of your operation and network to calculate necessary investments and expected returns..",
        serviceLeftTitle:
          "Marketing study, feasibility analysis and Business Plan",
        serviceLeftContent:
          "Cash payment deducted from monthly payment in case of closing.",
        serviceRightTitle: "Digital Bank Setup\n + Archiving Setup\n(machines)",
        serviceRightContent:
          "Prices vary depending on the feasibility study and your field of activity.",
        contactText: "Contact us",
        contactButtonText: "Know more",
        accordionOneTitle: "What is Banking as Service?",
        accordionOneText:
          "Banking as Service, which literally translates as Banking as a Service, authorizes companies from different segments, such as clothing stores and supermarkets, to offer financial services to customers without them needing to go to banks or financial institutions.",
        accordionTwoTitle:
          "The platform complies with Central Bank regulations?",
        accordionTwoText: "Yes, we comply with BACEN circular 3,765/15",
        accordionThreeTitle: "How long does it take to put my Bank online?",
        accordionThreeText:
          "After approval from your bank with the Central Bank, within 90 days.",
        accordionFourTitle: "What type of company do I need to operate?",
        accordionFourText:
          "Sociedade Ltda, ltda, Sa - If structured as an IP. We already have an IP, you will just be the intermediary.",
        questionsTitle: "COMMON",
        questionsTitleSupport: "QUESTIONS",
        footerRights: "All rights reserved.",
        footerTerms: "Terms of use",
        footerPrivacy: "Privacy Policy",
        digitalAccount: "Digital Account",
        transfer: "Transfers",
        credit: "Credit",
        invest: "Investments",
        prePaidCard: "Prepaid card",
        boleto: "Issuance\nof bills",
        banco: "24 Hour Bank\nand Withdrawal.",
        maquininha: "Machines",
        accountDebit: "Bill payment",
        digitalService:
          "Digital Services\n (cell phone recharge,\ncredits, uber,\nbetween others",
        firstBannerText: "BUILDING THE FUTURE TOGETHER",
        firstBannerTextSupport: "AND PREPARING",
        firstBannerTextFinal: "A NEW BANKING EXPERIENCE FOR YOU.",

        firstBannerText1: "Create your",
        firstBannerText2: "fintech",
        and: "and",
        firstBannerText3: "monetize",
        firstBannerText4: "with \neach transaction made \nby",
        firstBannerText5: "your ecosystem.",
        secondBannerText1: "Platform",
        secondBannerText2: "from Digital Bank",
        secondBannerText3: "Complete subacquiring \nin",
        secondBannerText4: "Private Label model.",
        thirdBannerText: "Innovation, \n Security \n and Commitment.",
        thirdBannerTextTwo: "These are some of the pillars of SB Bank.",
        thirdBannerTextThree: "Your confidence, ",
        thirdBannerTextFour: "our priority.",
        benefitsText: "Request your Service",
        benefitsTextSupport: "Contact us!",
        knowMoreButton: "Know more",
        aboutText: "Get in touch with the \n the best team of Experts",
        name: "Name",
        phone: "Phone Number",
        message: "Message",
        submit: "Submit",
        business: "Business",
        back: "Back",
        publicTextTitle:
          "Your company has a network of sellers, franchises, partners or customers?",
        in: "In ",
        aboutContentOneTwo:
          " days your bank is running and all responsibility for development, infrastructure, approvals with Brands and Acquirers and certifications are the responsibility of SB Bank.",

        publicTextContent:
          "With SB Bank you can offer a complete payment method solution (card machines, digital account, prepaid card...) with your brand. A new source of financial income for your company.",
        benefitsNewText:
          "We remove technological, financial, and bureaucratic barriers for those who need to move money easily. We offer proprietary solutions for payments, collections, receivables management, and you also earn commissions on online transactions or in payment terminals.",
        benefitsNewText2:
          "<strong>A simple and transparent business model:</strong> your company keeps a percentage of what is transacted through the POS and also on the fees charged to the digital account of users of your Digital Bank (example TED, PIX, issuance and bill...)",
        finTechBoostText1:
          "We create your company's digital bank in a few days.",
        finTechBoostText2:
          "Your company can operate as a financial institution,",
        finTechBoostText3:
          "transforming your ecosystem into an innovative source of financial revenue.",
        finTechBoostText4:
          "Your own digital bank and your brand in the hands of your customers.",
        finTechBoostText5: "Platform",
        oneHundred: "100%",
        finTechBoostText5Sup: "white label",
        finTechBoostText6:
          "with complete solutions increasing customer loyalty.",
        finTechBoostText7: "App and Internet Banking.",
      },
    },
  },
  lng: "pt",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
