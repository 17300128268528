import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../Styles/principal.css';

import prevButton from '../images/prev-arrow.svg';
import nextButton from '../images/next-arrow.svg';
import { FirstBanner, SecondBanner, ThirdBanner, ZeroBanner } from '../utils/Banners';

register();

SwiperCore.use([Navigation, Pagination]);

const imagesIndex = [
  // {
  //   banner1: <ZeroBanner id='image-principal'/>
  // },
  {
    banner2: <FirstBanner id='image-principal' />,
  },
  // {
  //   banner3: <SecondBanner id='image-principal' />,
  // },
  // {
  //   banner4: <ThirdBanner id='image-principal' />,
  // },
];

export default function Principal() {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const element = document.querySelector('.main-header');
    if (element instanceof HTMLElement) {
      setHeaderHeight(element.offsetHeight);
    }
  }, [headerHeight]);
  const swiper = useSwiper();

  const handlePrevButtonClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextButtonClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  return (
    <div
      style={{ paddingTop: headerHeight, position: 'relative' }}
      className='principal-container animate__animated animate__fadeIn'
    >
      {/* <div className='swiper-button-prev' onClick={handlePrevButtonClick}>
        <img src={prevButton} alt='prev button' />
      </div>
      <div className='swiper-button-next' onClick={handleNextButtonClick}>
        <img src={nextButton} alt='next button' />
      </div> */}
      <div className='principal-image'>
      <FirstBanner id='image-principal' />
        {/* <Swiper
          slidesPerView={1}
          autoplay={false}
          // pagination={true}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        > */}
          {/* {imagesIndex.map((item: Object, index: number) => (
            <SwiperSlide key={index}>{Object.values(item)[0]}</SwiperSlide>
          ))} */}
        {/* </Swiper> */}
      </div>
    </div>
  );
}
