import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

function AccordionQuestions() {
  const { t } = useTranslation();
  return (
    <Accordion defaultActiveKey='0'>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>{t('accordionOneTitle')}</Accordion.Header>
        <Accordion.Body>{t('accordionOneText')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header>{t('accordionTwoTitle')}</Accordion.Header>
        <Accordion.Body>{t('accordionTwoText')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='2'>
        <Accordion.Header>{t('accordionThreeTitle')}</Accordion.Header>
        <Accordion.Body>{t('accordionThreeText')}</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='3'>
        <Accordion.Header>{t('accordionFourTitle')}</Accordion.Header>
        <Accordion.Body>{t('accordionFourText')}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionQuestions;
