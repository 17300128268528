import React from 'react';
import '../Styles/footer.css';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';
import termosPdf from '../assets/termo_uso_sbbank.pdf';
import politicaPdf from '../assets/politica_privacidade_sbbank.pdf';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <span>© Copyright 2023</span>
        <span>{t('footerRights')}</span>
        <span>
          <a
            href={termosPdf}
            className='hoverFooter'
            target='_blank'
            download
            rel='noopener noreferrer'
          >
            {t('footerTerms')}
          </a>
        </span>
        <span>|</span>
        <span>
          <a
            target='_blank'
            className='hoverFooter'
            href={politicaPdf}
            download
            rel='noopener noreferrer'
          >
            {t('footerPrivacy')}
          </a>
        </span>
      </div>
    </footer>
  );
}
